<template>
    <div class="misc-wrapper">
        <b-link class="brand-logo">
            <b-img :src="logo" class="brand-logo-image" alt="soclib logo" width="120"/>
        </b-link>
        <div class="misc-inner p-2 p-sm-3">
            <div class="w-100 text-center">
                <h2 class="mb-1 font-weight-bolder text-primary">
                    Your Account Verified Successfully
                </h2>
                <p>
                    For login into application, your account must be activated
                    <br />
                    <strong> For more info, contact with administrator </strong>
                </p>
                <b-img fluid :src="imgUrl" alt="Error page" />
            </div>
        </div>
    </div>
</template>

<script>
import store from "@/store/index";
import { BLink, BAlert, BSpinner, BButton, BImg } from "bootstrap-vue";

export default {
    components: {
        BSpinner,
        BAlert,
        BLink,
        BButton,
        BImg,
    },
    data() {
        return {
            // alert properties
            alertCountDown: null,
            alertText: null,
            alertType: null,
            // image
            downImg: require("@/assets/images/pages/error.svg"),
        };
    },
    mounted() {
        if (!this.isVerified) {
            this.$router.push({ name: "error-404" });
        }
        // this.checkVerificationHandler();
    },
    computed: {
        imgUrl() {
            if (store.state.appConfig.layout.skin === "dark") {
                this.downImg = require("@/assets/images/pages/error-dark.svg");
                return this.downImg;
            }
            return this.downImg;
        },
        isVerified() {
            return this.$route.query.isVerified;
        },
        logo() {
            return require("@/assets/images/logo/logo_158.png")
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>
